const Masonry = require('masonry-layout');

import loading from "./component/loading";
// core modules

document.addEventListener("DOMContentLoaded", () => {
    loading();

    // const gallery = document.querySelector('[data-gallery]');
    // if (gallery) {
    //     const masonry = new Masonry( gallery, {
    //         itemSelector: '.gallery__image'
    //     });
    // }

});
