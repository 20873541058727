export default () => {

    const fades = document.querySelectorAll('[data-fadein]');
    function checkLoaded() {
        const w = window.innerHeight;
        const offset = 150;
        for (const dom of fades) {
            const rect = dom.getBoundingClientRect();
            if (rect.y < w - offset) {
                dom.classList.add('active');
            } else {
                if (parseInt(dom.getAttribute('data-once')) !== 1) {
                    dom.classList.remove('active');
                }
            }
        }
    }


    window.addEventListener("load", () => {
        window.setTimeout(() => {
            if (fades.length) {
                checkLoaded();
                window.addEventListener('scroll', () => {
                    checkLoaded()
                });
            }
        }, 500);
    });


}
